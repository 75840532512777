<template>
    <div class="container_l">
        <!-- 头部 -->
        <headerTab :headerConfig="headerConfig"></headerTab>
        <!-- 表單 -->
        <div class="content_l">
            <div class="title_l">合同信息</div>
            <div class="content_box_l" v-for="(item,index) in detailsList.audit_info" :key="index">
                <div class="right_l">
                    <div class="right_box_l">
                        <div>姓名：</div>
                        <div style="color:rgba(26, 26, 26, 0.50)">{{item.name}}</div>
                    </div>
                    <div class="right_box_l">
                        <div>工分：</div>
                        <div style="color:rgba(26, 26, 26, 0.50)">{{item.shares}}</div>
                    </div>
                </div>
                <div class="left_l">
                  <img :src="item.img" @click="lookIcon(item.img)"/>
                </div>
            </div>
            <!-- 物流信息 -->
            <div class="title_l_s">物流信息</div>
            <div class="logistics_info_l" style="margin-bottom:20px" v-if="detailsList.shipments">
                <div>{{detailsList.shipments[0].shipments_info.LogisticCode}}</div>
                <div class="address_a">{{detailsList.shipments[0].shipments_info.Shipper}}</div>
                <div v-if="showBtn" class="right_l_s" style="color:rgba(29, 140, 254, 1)" @click="falyBtn">展开 ></div>
                <div v-else class="right_l_s" style="color:rgba(29, 140, 254, 1)"  @click="falyBtn">收起 ></div>
            </div>
            <!-- 暂无物流信息 -->
            <div class="logistics_meesage_l" v-if="tableData.shipments.length==0">
                <img src="../../assets/cart.png"/>
                <div>暂无数据</div>
            </div>

            <div v-else>
              <logistics :dataList="dataList" :showBtn="showBtn" :alterationShow="alterationShow" :tableData="tableData"></logistics>
            </div>
        </div>
    </div>
</template>
<script>
import headerTab from "@/components/headerTab";
import {sharesChangeDetails_api} from "@/api/alteration";
import logistics from "@/components/logistics";
import { getShipments_api} from "@/api/admin";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      headerConfig: {
        title: "处理进度"
      },
      detailsList:{
        shipments:[
          {shipments_info:{}}
        ]
      },
      dataList:{},
      tableData:{},
      alterationShow:true,
      showBtn:true
    };
  },
  components: {
    headerTab,
    logistics
  },
  created() {
    document.title = "处理进度";

    this.getDetails(this.$route.query.id)
    console.log(123);
  },
  methods: {
    // 查看合同
    lookIcon(img) {
      console.log(img);
      ImagePreview([img]);
    },
    falyBtn(){
      this.showBtn = !this.showBtn
    },
    getDetails(id){
      sharesChangeDetails_api(id).then(res=>{
        if(res.code==0){
          this.detailsList = res.data;
          this.tableData = res.data
          this.getShipments(this.detailsList.shipments[0].shipments_info)
        }
      })
    },
    submitBtn() {},
    getShipments(data){
            data.logistic = 0
            getShipments_api(data).then(res=>{
                if(res.code==0){
                    console.log(res)
                    this.dataList = res.data
                    this.dataList.Traces.reverse();
                }
            })
        }
  }
};
</script>
<style>
.logistics_info_l .address_a{
  margin-right:200px;

}
.container_l {
  background-color: #f9f9f9;
  width: 100%;
}
.content_l {
  width: 700px;
  margin: 30px auto 0;
  background-color: #fff;
  padding: 40px;
  box-sizing: border-box;
}
.title_l {
  font-size: 34px;
  color: #1a1a1a;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
}
.title_l_s {
  font-size: 34px;
  color: #1a1a1a;
  padding-bottom: 30px;
  margin-top: 30px
  }
.content_box_l {
  display: flex;
}
.right_box_l {
  width: 420px;
  height: 80px;
  line-height: 40px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(26, 26, 26, 0.2);
  display: flex;
  font-size: 30px;
  padding: 20px 24px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.left_l {
  width: 180px;
  height: 180px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(26, 26, 26, 0.2);
  margin-left:20px
}
.left_l img{
  width:100%;height:100%
}
.content_box_l{
    padding-bottom: 20px;
    border-bottom: 1px solid #eee
}
.logistics_info_l{
    display:flex;
    font-size:30px;
    color:rgba(26, 26, 26, 1);
    position: relative;
}
.right_l_s{
  position:absolute;
  right:10px;top: 5px
}
.logistics_info_l div{
    margin-right:20px
}
.logistics_meesage_l img{
    width:70px;

}
.logistics_meesage_l{
    font-size:24px;
    color:rgba(26, 26, 26, 0.30);
    text-align: center;
    margin-top: 50px
}
</style>


